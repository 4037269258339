import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../../services/config';
import logo from '../../assets/conexi_branco.png';

function SideBar({history, usuario, opcao}) {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/home" className="brand-link text-center">
            <img src={logo} style={{width: 134}} className="logo-topo"/>
        </a>

        <div className="user-panel mt-3 pb-3 mb-3 d-flex">            
            <div className="info">
                <b className="brand-text text-white">{usuario.nome}</b><br/>
                <small className="brand-text text-white">{usuario.cargo}</small>
            </div>
        </div>

        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                <li className="nav-item">
                    <Link to="#" className={'nav-link ' + (opcao === 'edificios' ? 'active' : '')}>
                        <i className="nav-icon fas fa-building"></i>
                        <p>Edifícios</p>
                    </Link>
                </li>
                
                <li className="nav-item">
                    <Link to="/empresas" className={'nav-link ' + (opcao == 'empresas' ? 'active' : '')}>
                    <i className="nav-icon fas fa-store-alt"></i>
                    <p>Empresas</p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="#" className={'nav-link ' + (opcao == 'configuracoes' ? 'active' : '')}>
                    <i className="nav-icon fas fa-cog"></i>
                    <p>Configurações</p>
                    </Link>
                </li>

                {usuario.id_perfil == '2' ? (
                    <li className="nav-item">
                        <Link to="#" className={'nav-link'}>
                            <i className="nav-icon fas fa-coins"></i>
                            <p>Tabelas de Apoio</p>
                        </Link>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/atos" className={'nav-link ' + (opcao == 'ato' ? 'active' : '')}>
                                <i className="fas fa-book nav-icon"></i>
                                <p>Atos</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/tiposentidade" className={'nav-link ' + (opcao == 'tiposentidade' ? 'active' : '')}>
                                <i className="fas fa-book nav-icon"></i>
                                <p>Tipo de Entidade</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/capa" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Capa Principal</p>
                                </a>
                            </li>                            
                        </ul>
                    </li>
                ):(
                    <li className="nav-item">
                        <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao == 'tabelas' ? 'active' : '')}>
                        <i className="nav-icon fas fa-coins"></i>
                        <p>
                            Tabelas de Apoio 
                        </p>
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
        <div className="" style={{
               width: '100%',
               position: 'absolute',
               bottom: 0,
               textAlign: 'center',
               color: '#FFFFFF',
               fontSize: 12,
               padding: 10,
        }}>
          Versão {config.versao}
        </div>
    </aside>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(SideBar);