import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../assets/bg2.jpg';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: 0;
    font-size: 12px!important;
  }

  .logo-topo{
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }

  .h1 {
    font-size: 40px!important;
  }

  *:focus{
    outline: 0;
  }

  html, body {
    background: #FFF;    
    height: auto;
    min-height: 100%;
  }

  #root {  
    background-color: #FFF;
    height: 100vh;
    width: 100%;
    position: absolute;
  }

  body {
    background: #FFF;    
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  .main-content{
    padding-bottom: 80px!important;
  }

  .dv-star-rating-star i {
      margin-left: 2px;
      font-size: 22px!important;
  }

  button {
    cursor: pointer;
  }

  .button-icon {
    cursor: pointer;
  }
  .btn-size-1{
    font-size: 16px!important;
  }

  #carregando{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }

  .loading-indicator {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    img {
      position: relative;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  .content-wrapper {
    height: calc(100vh - 57px);    
  }
  .react-datepicker-wrapper {
    display: block!important;
  }

  .required {
    color: red;
  }

  .disabled{
    cursor: not-allowed!important;
    background: #ccc;
  }

  .menu-disabled{
    cursor: not-allowed!important;
  }

  .ck-editor__editable {
    height: 300px!important;
    max-height: 300px!important;
  }

  .tox-statusbar{
    display: none !important;
  }

  .padding-bottom {
    padding-bottom: 65px!important;
  }

  .tox-editor-header {
    position: sticky!important;
    display: contents;
  }

  .tabela {
    display: block;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .header-site{
    h1 {
      font-size: 4.5rem!important;
      font-weight: bold;
    }
    .lead{
      font-weight: bold;
    }
    background: linear-gradient(
      rgba(0, 0, 0, 0.4), 
      rgba(0, 0, 0, 0.4)
    ), url(${bg});

    .btn {
      height: 37px!important;
    }
    background-size: cover;
    background-position: center;
    padding: 4rem 5rem;
    height: auto;
    color: white!important;
  }

  .header-site-mobile{
    h1 {
      font-size: 3.5rem!important;
      font-weight: bold;
    }
    .lead{
      font-weight: bold;
    }
    background: linear-gradient(
      rgba(0, 0, 0, 0.4), 
      rgba(0, 0, 0, 0.4)
    ), url(${bg});

    .btn {
      height: 37px!important;
    }
    background-size: cover;
    background-position: center;
    padding: 2rem 2rem;
    height: auto;
    color: white!important;

    hr {
      border-top: 1px solid rgba(255,255,255,0.1);
    }
  }

  .titulo-destaque{
    font-size: 3em!important;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    color: rgba(0,0,128, 0.8);
  }

  .titulo-mais-procurados{
    font-size: 1.5em!important;
    font-weight: bold;
    padding: 5px;
    color: rgba(0,0,128, 0.8);  
  }

  .card-destaque {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-destaque img {
    height: 190px!important;
  }

  .card-title-destaque{
    font-size: 22px!important;
    font-weight: bold;
    color: rgba(0,0,128);
  }

  .card-img, .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .detalhe-titulo-empresa{
    font-size: 18px!important;
  }

  .h1 {
    font-size: 20px!important;
  }

  .links a{
    color: #C0C0C0!important;
    line-height: 22px!important;
  }

  .links a:hover{
    color: #FFF!important;
  }

  .titulo-resultado{
    font-size: 28px!important;
  }

  .subtitulo-resultado{
    font-size: 22px!important;
  }

  .titulo-page-empresa{
    font-size: 32px!important;
    font-weight: bold;
  }

  .page-header{
    color: #FFF;
    height: 420px;
  }

  .link-site{
    color: rgba(0,0,128)!important;
    font-weight: bold;
  }

  .page-link-menu{
    color: #FFF!important;
  }


  // carousel
  @media (min-width: 768px) {

/* show 3 items */
.carousel-inner .active,
.carousel-inner .active + .carousel-item,
.carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
}

.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
    margin-right: initial;
}

.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    z-index: -1;
    display: block;
    visibility: visible;
}

/* left or forward direction */
.active.carousel-item-left + .carousel-item-next.carousel-item-left,
.carousel-item-next.carousel-item-left + .carousel-item,
.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
}

/* farthest right hidden item must be abso position for animations */
.carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
}

/* right or prev direction */
.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
.carousel-item-prev.carousel-item-right + .carousel-item,
.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
}

}

.sobre-empresa{
  margin-top: 20px;
  font-size: 20px!important;
}
`;
