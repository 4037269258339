import React from 'react';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { connect, useDispatch, useSelector } from 'react-redux';

function HomeAdmin({history}) {
  return (
    <>
        <NavBar history={history}/>
        <SideBar />
        <div className="content-wrapper main-content">            
            <section className="content">
                <div className="container-fluid">
                    <br />
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="h1">Seja Bem-Vindo(a)!</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
        </aside>
        <Footer />
    </>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(HomeAdmin);