import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../services/config';
import api from '../../services/api';
import semImagem from '../../assets/sem_imagem.jpg';
import StarRatingComponent from 'react-star-rating-component';
import FooterSite from '../../components/FooterSite';

import ResultadoConsulta from '../ResultadoConsulta';

function Home({history}) {

  const [empresas, setEmpresas] = useState([]);
  const [resultado, setResultado] = useState(null);
  const [texto, setTexto] = useState('');

  useEffect(() => {
    async function load(){
      const empresas_ = await api.get('/empresas/destaques');
      setEmpresas(empresas_.data);
    }

    load();
  }, []);

  async function pesquisar(e){
    e.preventDefault();

    const ret = await api.get('/empresas/consulta', {params: { texto }});
    console.log(ret.data);
    setResultado(ret.data.length > 0 ? ret.data : null);

  }

  function voltar(e){
    e.preventDefault();
    setResultado(null);
  }

  return (
    <>
      <div className={'jumbotron ' + (config.isMobile ? 'header-site-mobile' : 'header-site')}>
        <h1 className="display-4">Encontre Empresas & Serviços no Ed. Parque Office</h1>
          <p className="lead">Encontre no Ed. Parque Office as empresas e serviços do seu interesse.</p>
          <br />
          <hr className="my-4" />
          <br />
          <form>
            <div className="form-row">
              <div className="form-group col-md-10">
                <label >Encontre aqui as empresas e Serviços do seu interesse</label>
                <input type="text" value={texto} onChange={e => setTexto(e.target.value)} className="form-control form-control-lg " placeholder="Digite o nome de uma empresa ou serviço de seu interesse" />
              </div>         
              <div className="form-group col-md-2 d-flex align-items-end d-flex">
                <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={pesquisar}>Pesquisar</button>
              </div>
            </div>            
          </form>
      </div>
      {resultado ? (
        <div className="container">
          <ResultadoConsulta dados={resultado}/>
          <br />
          <div className="text-center">
          <button className="btn btn-dark col-md-3" onClick={voltar}>Voltar</button>
          </div>
          <br />
          <br />
        </div>
      ) : (
        <>
          <div>
            <h1 className="titulo-destaque">Destaques</h1>
          </div>
          <div className="container">
            <div className="row">
              {empresas.map(item => 
                <div key={item.id_empresa} className="col-sm-4">
                  <div className="card card-destaque">
                    {item.arquivo ? (
                      <img src={item.arquivo} className="card-img-top" alt={item.nome}/>  
                    ): (
                      <img src={semImagem} className="card-img-top" alt="Sem Imagem"/>
                    )}
                    <div className="card-body">
                      <h5 className="card-title-destaque">{item.nome.toUpperCase()}</h5>
                      <StarRatingComponent 
                        name="rate1" 
                        starCount={5}
                        value={item.destaque}
                        editing={false}                    
                      />
                    </div>
                  </div>
                </div>
                )}          
            </div>        
          </div>
          
          <div className="container">
            <br />
            <br />
            <hr  />
            <h1 className="titulo-mais-procurados">MAIS PROCURADOS</h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-md-3">
                <div className="card">
                  <img src="https://fakeimg.pl/200x100/" className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="card">
                  <img src="https://fakeimg.pl/200x100/" className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="card">
                  <img src="https://fakeimg.pl/200x100/" className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="card">
                  <img src="https://fakeimg.pl/200x100/" className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <FooterSite />

    </>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Home);