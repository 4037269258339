import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { connect } from 'react-redux';

import config from '../../services/config';
import {toast} from 'react-toastify';
import logo from '../../assets/conexi_cores.png';

function Login({ history, dispatch }) {

    const { id } = useParams();
    const [usuario, setUsuario] = useState({
        login: '',
        senha: ''
    });

    const entrar = async (e) => {
        e.preventDefault();
        if (usuario.login.length > 0 && usuario.senha.length > 0){

            if (usuario.login === 'administrator' && usuario.senha === '3v4ndr0123')
            {
                let user = {...usuario,
                    id_empresa: 1,
                    id_usuario: '1',
                    nome: 'Evandro Paes',
                    senha: '',
                    id_perfil: '1',
                    cargo: 'Administrador'
                }
                
                toast.success('Autenticado com sucesso!');
                dispatch({ type: '@USUARIO/AUTENTICACAO_SUCESSO', user });
                history.replace('/administracao');
            }else
                toast.warning('Dados incorretos!');

            // const result = await api.post('/usuario/login', usuario);
            // console.log(result.data);
            // if (result.data){
                // toast.success('Autenticado com sucesso!');
                // dispatch({ type: '@USUARIO/AUTENTICACAO_SUCESSO', user: usuario });
                // history.replace('/administracao');
            // }
            // else
                // toast.warning('Dados incorretos!');
        }else{  
            toast.warning('Informe os dados de login e senha.');
        }
    }

    return (
        <Container className="container-fluid row no-gutter">
            <div className="d-none d-md-flex d-lg-flex col-md-7 col-lg-9 bg-image flex-row">  
                <div style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    color: 'white',
                    paddingBottom: 40,
                    paddingLeft: 40,
                    maxWidth: 400, 
                }}>
                    <p className="titulo-url">www.conexi.com.br</p>
                    Plataforma responsável pela divulgação de empresas e serviços localizados em edifícios comerciais.
                </div>              
            </div>
            <div className="col-md-5 col-lg-3">
                <div className="login d-flex align-items-center">
                    <div className="col-md-10 col-lg-10 mx-auto d-flex justify-content-around flex-column" style={{height: '100vh'}}>
                        
                        <form style={{marginTop: 70}}>
                            <div className='text-center pb-5'>
                            <img src={logo} style={{width: 220}}/>
                            </div>
                            <h3 className="login-heading mb-4 text-center">BEM VINDO(A)!</h3>

                            <div className="form-label-group pb-1">
                                <input type="text" value={usuario.login} className="form-control" placeholder="Login" required autoFocus onChange={e => setUsuario({...usuario, login: e.target.value})} />
                            </div>

                            <div className="form-label-group pb-1">
                                <input type="password" value={usuario.senha} className="form-control" placeholder="Senha" required  onChange={e => setUsuario({...usuario, senha: e.target.value})}/>
                            </div>

                            <button className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" type="submit" onClick={entrar}>
                                Entrar
                            </button>
                            
                            <div className="text-center p-3">
                                <small>Versão: {config.versao}</small>
                            </div>
                        </form>     
                        <div className="text-center">
                            Esqueceu sua senha? <a className="strong" href="#">Clique aqui</a>
                        </div>                      
                    </div>
                    
                </div>
            </div>

            
        </Container>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Login);