function isMobile() {
  console.log(navigator.userAgent);
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i) !== -1)
    return true;
}

export default {
  versao: '0.2.2',
  nome: 'Conexi',
  isMobile: isMobile(),
  urlLogo: 'https://diariooficial.netlify.app/logos/',
  baseURL: "https://api.conexi.com.br/",
  baseSite: "https://parqueoffice.conexi.com.br/",
  // baseURL: "http://api.edificiocomercial.com/",
};