import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from './pages/Login';
import Home from './pages/Home';
import HomeAdmin from "./pages/HomeAdmin";

import Page404 from "./pages/Page404";
import EmpresaConsulta from "./pages/EmpresaConsulta";
import EmpresaCadastro from "./pages/EmpresaCadastro";
import EmpresaPage from "./pages/EmpresaPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      localStorage.getItem('edificio.usuario') ? (
        <Component {...props} />
      ) : (
          <Redirect to={`/`} />
        )
    )} />
  )
}

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />     
      <PrivateRoute path="/administracao" component={HomeAdmin} />
      <PrivateRoute path="/empresas" component={EmpresaConsulta} />
      <PrivateRoute path="/empresa_cad" component={EmpresaCadastro} />
      <Route exact path="/admin" component={Login} />
      <Route path="/404" component={Page404} />      

    </Switch>
  </BrowserRouter>
);

export default Routes;
