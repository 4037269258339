import React from 'react';

import google from '../../assets/google.png';
import twitter from '../../assets/twitter.png';
import facebook from '../../assets/facebook.png';

import config from '../../services/config';
import { Link } from 'react-router-dom';

function FooterSite() {
  return (
    <div  style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#000',
      padding: 10,
      color: '#FFF',
      paddingTop: 56,
      paddingBottom: 36
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <strong className="h1" style={{fontSize: 26,}}>Edifícil Comercial</strong><br />
            <div style={{marginTop: 10}}>Serviço responsável por divulgar emrpresas e serviços em edifícios comerciais.</div>
            <div style={{marginTop: 10}}>
              <img src={facebook} width="32" height="32"/>
              <img style={{marginLeft: 5}} src={twitter} width="32" height="32"/>
              <img style={{marginLeft: 5}} src={google} width="32" height="32"/>
            </div>
          </div>

          <div className="col-md-2 links">
            <strong className="h1" style={{fontSize: 20,}}>Ed. Comerciais</strong><br />
            <Link to="#">Sobre</Link><br />
            <Link to="#">Como Divulgar</Link><br />
            <Link to="#">Edifícios Comerciais</Link><br />
            <Link to="#">Contato</Link><br />
          </div>

          <div className="col-md-2 links">
            <strong className="h1" style={{fontSize: 20,}}>Parque Office</strong><br />
            <Link to="#">Sobre</Link><br />
            <Link to="#">Buscar Serviços</Link><br />
            <Link to="#">Contato</Link><br />
          </div>     
          <div className="col-md-3 links">
            <strong className="h1" style={{fontSize: 20,}}>Contato</strong><br />
            <Link to="#"><i className="fas fa-envelope"></i> contato@edificiocomercial.com.br</Link><br />
            <Link to="#"><i className="fas fa-phone-alt"></i> (91) 98128-0772</Link><br />
            <Link to="#"><i className="fas fa-map-marker-alt"></i> Ed. Parque Office, 4300</Link><br />
          </div>      
        </div>
        <br />
        <br />
        {config.isMobile ? (
          <div className="row">
            <div className="col-md-12 text-center">              
              www.edificiocomercial.com.br
              <br /><br />
            </div>

            <div className="col-md-12 text-center">
              &copy; 2021 - Todos os direitos reservados
            </div>
            
            <div className="col-md-12 text-center">
              Desenvolvido por <a href="http://jetatecnologia.com.br/" target="_blank">Jeta Tecnologia</a>
            </div>            
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6">
              &copy; 2021 - Todos os direitos reservados | Desenvolvido por <a href="http://jetatecnologia.com.br/" target="_blank">Jeta Tecnologia</a>
            </div>
            <div className="col-md-6 text-right">
              www.edificiocomercial.com.br
            </div>
          </div>
        )}
      </div>
      
    </div>
  );
}

export default FooterSite;