import React, {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';

function EmpresaConsulta({history, location, usuario}) {

    const [registro, setRegistro] = useState(null); 
    const [lista, setLista] = useState(null);
    const [desstatus, setDesStatus] = useState('');
    const [filtro, setFiltro] = useState({
        texto: '',        
        categoria: '',
        nome: '',
        status: '',
    });
    const [categorias, setCategorias] = useState([]);
    const [categorias_empresa, setCategoriasEmpresa] = useState([]);
    const [servicos_empresa, setServicosEmpresa] = useState([]);
    const [destaques_empresa, setDestaquesEmpresa] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [imagens, setImagens] = useState([]);
    const [imagem, setImagem] = useState(null);
    const [area, setArea] = useState('0');

    function toggleDrop(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        async function carregaTabelas(){
            const result = await api.get('/empresas', {params : filtro});
            setLista(result.data ? result.data : null);

            const resCategorias = await api.get('/categorias');
            setCategorias(resCategorias.data);
            
        }

        carregaTabelas();

    }, []);

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/empresas/consulta', {params : filtro});
        setLista(result.data ? result.data : null);
    }
        
    function viewItem(e, item){
        e.preventDefault();
        history.push('/empresa_cad', {registro: item});
    }

    async function carregaServicos(id_empresa){
        var retServ = await api.get('/empresa/servico', { params: {
            id_empresa: id_empresa,
        }});
        setServicosEmpresa(retServ.data);
    }

    async function carregaDestaques(id_empresa){
        var retDest = await api.get('/empresa/destaque', { params: {
            id_empresa: id_empresa,
        }});
        setDestaquesEmpresa(retDest.data);

    }

    async function carregaImagens(id_empresa){
        var retImg = await api.get('/imagem', { params: {
            id_empresa,
        }});
        console.log(retImg.data);
        setImagens(retImg.data);

    }

    async function viewImages(e, item){
        e.preventDefault();
        var ret = await api.get('/empresa/categoria', { params: {
            id_empresa: item.id_empresa,
        }});
        setCategoriasEmpresa(ret.data);

        carregaServicos(item.id_empresa);
        carregaDestaques(item.id_empresa);
        carregaImagens(item.id_empresa);
        setRegistro(item);
    }

    function editItem(e, item){
        e.preventDefault();
        history.push('/empresa_cad', {registro: item});
    }

    async function confirmStatus(){
        const novostatus = registro.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'Desativado' : 'Ativado';

        const del = await api.put('/empresa/status', {id_empresa: registro.id_empresa, status: novostatus});
        const result = await api.get('/empresas', {params : filtro});
        
        window.$('#modalConfirm').modal('toggle');
        toast.success(`Empresa ${desstatus} com sucesso`);
        setLista(result.data);
    }

    function statusSwitch(item){

        const novostatus = item.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'DESATIVAR' : 'ATIVAR';
        setDesStatus(desstatus);
        setRegistro(item);
    }
    
    async function handleChange (event){
        console.log(selectedId);
        // console.log(event.target.files[0].name);
        if (event.target.files.length > 0)
        {
            let postdata = new FormData();
            postdata.append('file', event.target.files[0]);
            postdata.append('area', selectedArea);
            postdata.append('id_empresa', registro.id_empresa);
            const resp = await api.post('/upload', postdata);

            const upImage = await api.post('/upload/save', {
                uri: resp.data.uri,
                id: selectedId,
                area: selectedArea,
            });
            
            console.log(upImage.data)

            carregaServicos(registro.id_empresa);
            carregaDestaques(registro.id_empresa);
        }
    };

    const inputRef = useRef(null);

    const handleOpenFileInput = (event, area, id) => {
        setSelectedId(id);
        setSelectedArea(area);
        inputRef.current.click(event);
    };

    function handleImagem (e) {
        if (e.target.files.length > 0)
        {
            setImagem(e.target.files[0]);
        }else{
            setImagem(null);
        }
    }

    async function addImagem(e){
        e.preventDefault();
        if (area == '0'){
            if (imagens.find(img => img.area == '0')){
                toast.error('Já existe uma imagem para a área selecionada.');
                return false;
            }
        }

        if (area == '2'){
            if (imagens.find(img => img.area == '2')){
                toast.error('Já existe uma imagem para a área selecionada.');
                return false;
            }
        }

        if (area == '1'){
            const imgs = imagens.filter(img => img.area == '1');
            if (imgs.length === 3)
            {
                toast.error('Quantidade máxima já atingida para a área selecionada.');
                return false;
            }
        }

        if (imagem)
        {
            let postdata = new FormData();
            postdata.append('file', imagem);
            postdata.append('area', area);
            postdata.append('id_empresa', registro.id_empresa);
            const resp = await api.post('/upload', postdata);

            const upImage = await api.post('/imagem', {
                arquivo: resp.data.uri,
                id_empresa: registro.id_empresa,
                area,
            });
            

            setImagens([...imagens, {
                file: imagem,
                arquivo: resp.data.uri,
                area: area,
                id_empresa: registro.id_empresa,
                delete: 'N',
            }]);
            carregaImagens(registro.id_empresa);
        }
        setImagem(null);
    }

    async function removeImagem(id_imagem){

        const result = await api.post('/imagem/delete', { id_imagem });
        console.log(result);
        carregaImagens(registro.id_empresa);
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="empresas"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Consulta Emrpesas</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Empresas ou Serviço</label>
                                            <input type="text" className="form-control" value={filtro.texto} onChange={e => setFiltro({...filtro, texto: e.target.value})}/>
                                        </div>  

                                        <div className="form-group col-md-3">
                                            <label>Categoria</label>
                                            <select className="form-control"  value={filtro.categoria} onChange={e => setFiltro({...filtro, categoria: e.target.value})} value={filtro.categoria}>
                                                <option value="">Todos</option>
                                                {categorias.map((item, index) => (
                                                    <option key={index} value={item.id_categoria}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>  

                                        <div className="form-group col-md-2">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, status: e.target.value})} value={filtro.status}>
                                                <option value="">Todos</option>
                                                <option value="0">Ativos</option>
                                                <option value="1">Inativos</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="form-row">
                                        <div className={'form-group col'}>
                                            <button className="btn btn-primary col-md-3 col-sm-6" onClick={consutlar}>Pesquisar</button>
                                        </div>
                                        <div className={'form-group col text-right'}>
                                            <a className="btn btn-success col-md-3 col-sm-6" href="/empresa_cad">Novo</a>
                                        </div>
                                    </div>                                   
                                </form>
                            
                                <hr />
                                <table className="table table-striped">
                                    <thead >
                                        <tr>
                                            <th>EMPRESA</th>
                                            <th>URL</th>
                                            <th>DT CAD.</th>
                                            <th>STATUS</th>
                                            <th style={{width: 50}}>OPÇÕES</th>
                                        </tr>
                                    </thead> 
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.nome}</td>
                                                <td><a href={ config.baseSite + item.url} target="_blank">{item.url}</a></td>
                                                <td>{item.data_cadastro}</td>
                                                <td className="text-center" style={{width: 80}}>{item.status == '0' ? 'Ativo' : 'Inativo'}</td>
                                                <td className="text-center" >
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            <a className="dropdown-item" href="#" onClick={e => editItem(e, item)}>Editar</a>
                                                            <a className="dropdown-item" href="#" onClick={e => viewImages(e, item)} data-toggle="modal" data-target="#myModal">Imagens</a>
                                                            <a className="dropdown-item" href="#" onClick={e => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{item.status == '0' ? 'Desativar' : 'Ativar'}</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">Nenhum registro encontrado</td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {registro && (
                                <strong>Gostaria de {desstatus} a Empresa "{registro.nome}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="myModal" tabIndex="-1" aria-hidden="true">
                <input  ref={inputRef} type="file" onChange={handleChange} style={{display: 'none',}}/>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                {registro && (
                    <div className="modal-content">
                    <div className="modal-body">

                        <h1 className="detalhe-titulo-empresa text-center">Cadastro de Imagens</h1><br />
                        <h1 className="detalhe-nome-empresa text-center">{registro.nome}</h1><br />

                        <form>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label>Área</label>
                                    <select className="form-control" value={area} onChange={e => setArea(e.target.value)} >
                                        <option value="0">Topo</option>
                                        <option value="1">Sobre</option>
                                        <option value="2">Topo Mini</option>
                                    </select>
                                </div> 
                                <div className="form-group col-md-7 d-flex align-items-end">                                                    
                                    <div className="custom-file">
                                        <input onChange={handleImagem} type="file" className="custom-file-input"/>
                                        <label  className="custom-file-label">{imagem ? imagem.name : 'Seleione uma imagem'}</label>
                                    </div>
                                </div>  
                                <div className="form-group col-md-2 d-flex align-items-end">                                            
                                    <button onClick={addImagem} className="btn btn-primary btn-block">Adicionar</button>
                                </div>  
                            </div> 
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Área</th>
                                                <th style={{width: 50,}}>Imagem</th>
                                                <th style={{width: 50,}}>Opções</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {imagens.length > 0 ? imagens.map((item,index) => 
                                                <tr key={index} style={{color: (item.delete == 'S' ? 'red' : '')}}>
                                                    <td>{item.area == '0' ? 'Topo' : (item.area == '1' ? 'Sobre' : 'Topo Mini')}</td>
                                                    <td  className="text-center">
                                                        <a href={item.arquivo} title="Visualizar imagem" target="_blank"><i className="fas fa-eye ml-2 button-icon btn-size-1"></i></a>
                                                    </td>
                                                    <td style={{width: 50,}} className="text-center"><i onClick={(e) => removeImagem(item.id_imagem)} className="far fa-trash-alt button-icon"></i></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="text-center">Nenhum imagem encontrada</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                    
                            <hr />
                        </form>

                       
                        <div className="text-center p-2"><strong>Destaques</strong></div>
                        <table className="table table-striped table-bordered">
                            <thead >
                                <tr>
                                    <th>Destaque</th>
                                    <th className="text-center" style={{width: 60}}>Imagem</th>
                                    <th className="text-center" style={{width: 60}}>Upload</th>
                                </tr>
                            </thead> 
                            <tbody>
                                {destaques_empresa.length > 0 ? destaques_empresa.map((item, index) => (
                                    <tr key={index}>
                                        <td >{item.titulo}</td>
                                        <td className="text-center" >
                                            {item.imagem && (
                                                <a href={item.imagem} title="Visualizar imagem" target="_blank"><i className="fas fa-eye ml-2 button-icon btn-size-1"></i></a>
                                            )}
                                        </td>
                                        <td className="text-center">
                                            <a href="#" title="Enviar imagem" onClick={(e) => handleOpenFileInput(e, '3', item.id_destaque)}><i className="fas fa-cloud-upload-alt button-icon btn-size-1"></i></a>
                                        </td>
                                    </tr>    
                                )) : (
                                    <tr>
                                        <td colSpan={3} className="text-center">Nenhum registro encontrado</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <hr/>

                        <div className="text-center p-2"><strong>Serviços</strong></div>
                        <table className="table table-striped table-bordered">
                            <thead >
                                <tr>
                                    <th>Serviço</th>
                                    <th className="text-center" style={{width: 60}}>Imagem</th>
                                    <th className="text-center" style={{width: 60}}>Upload</th>
                                </tr>
                            </thead> 
                            <tbody>
                                {servicos_empresa.length > 0 ? servicos_empresa.map((item, index) => (
                                    <tr key={index}>
                                        <td >{item.nome}</td>
                                        <td className="text-center" >
                                            {item.imagem && (
                                                <a href={item.imagem} title="Visualizar imagem" target="_blank"><i className="fas fa-eye ml-2 button-icon btn-size-1"></i></a>
                                            )}
                                        </td>
                                        <td className="text-center">
                                            <a href="#" title="Enviar imagem" onClick={(e) => handleOpenFileInput(e, '2', item.id_servico)}><i className="fas fa-cloud-upload-alt button-icon btn-size-1"></i></a>
                                        </td>
                                    </tr>    
                                )) : (
                                    <tr>
                                        <td colSpan={3} className="text-center">Nenhum registro encontrado</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <hr/>

                        <div className="text-center p-2"><strong >Categorias</strong></div>
                        <table className="table table-striped table-bordered">
                            <thead >
                                <tr>
                                    <th>Categoria</th>
                                    {/* <th>Imagem</th> */}
                                    {/* <th style={{width: 80}}></th> */}
                                </tr>
                            </thead> 
                            <tbody>
                                {categorias_empresa.length > 0 ? categorias_empresa.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.nome}</td>
                                        {/* <td></td>
                                        <td className="text-center">
                                            <a href="#" title="Enviar imagem"><i className="fas fa-cloud-upload-alt button-icon btn-size-1"></i></a>
                                            <a href="#" title="Visualizar imagem"><i className="fas fa-eye ml-2 button-icon btn-size-1"></i></a>
                                        </td> */}
                                    </tr>    
                                )) : (
                                    <tr>
                                        <td colSpan={1} className="text-center">Nenhum registro encontrado</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div> 
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    </div>
                    </div>
                )}           
                </div>
            </div>

            
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(EmpresaConsulta);