import React from 'react';
import { Link } from 'react-router-dom';

// import { Container } from './styles';
import StarRatingComponent from 'react-star-rating-component';
import semImagem from '../../assets/sem_imagem.jpg';

function ResultadoConsulta({dados}) {
  return (
    <>
            <h1 className="titulo-resultado" style={{fontWeight: 'bold',}}>
                Resultado da Busca
            </h1> 
            <h2 className="subtitulo-resultado" style={{fontWeight: 'bold',}}>{dados.length} empresas encontradas</h2>
            <br />
            {dados ? (
                <div className="row">
                    {dados.map(item => 
                    <div key={item.id_empresa} className="col-sm-4">
                        <Link to={'/parqueoffice/' + item.url}>
                        <div className="card card-destaque">
                            {item.arquivo ? (
                                <img src={item.arquivo} className="card-img-top" alt={item.nome}/>  
                            ): (
                                <img src={semImagem} className="card-img-top" alt="Sem Imagem"/>
                            )}
                            <div className="card-body">
                                <h5 className="card-title-destaque">{item.nome.toUpperCase()}</h5>
                                <StarRatingComponent 
                                name="rate1" 
                                starCount={5}
                                value={item.destaque}
                                editing={false}                    
                                />
                            </div>
                        </div>
                        </Link>
                    </div>
                    )}          
                </div>  
            ) : (
                <>
                    Nenhum registro foi encontrado.
                </>
            )}

    </>
    )
}

export default ResultadoConsulta;