import React from 'react';

import {Route} from 'react-router-dom';

function NavBar({history}) {
  
  const sair = e => {
    e.preventDefault();
    localStorage.removeItem('edificio.usuario');
    history.push('/admin');
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
        </li>        
      </ul>
  
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={sair} role="button">
            Sair <i className="fas fa-sign-out-alt"></i>
          </a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
            <i className="fas fa-th-large"></i>
          </a>
        </li> */}
      </ul>
    </nav>
  );
}

export default NavBar;