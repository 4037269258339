import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import api from '../../services/api';
import bg from '../../assets/bg.jpeg';
import FooterSite from '../../components/FooterSite';
import config from '../../services/config';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import google from '../../assets/google.png';
import twitter from '../../assets/twitter.png';
import facebook from '../../assets/facebook.png';

function EmpresaPage({ history }) {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const { url } = useParams();
  const [empresa, setEmpresa] = useState(null);
  const [imgTopo, setImgTopo] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    async function load(){
      const result = await api.get('/empresa_url', { params: {url}});
      setEmpresa(result.data);
      
      const resServicos = await api.get('/empresa/servico', { params: {id_empresa: result.data.id_empresa}});
      setServicos(resServicos.data);
      console.log(resServicos.data);

      const resImagens = await api.get('/imagem', { params: {id_empresa: result.data.id_empresa}});
      setImagens(resImagens.data);
      const topo = resImagens.data.find(item => item.area == '0');
      if (topo)
        setImgTopo(topo.arquivo);


    }

    load();
  }, []);

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="#"><strong>PARQUE OFFICE</strong></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end">
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a className="nav-link link-site" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link link-site" href="/">Buscar Serviços</a>
            </li>            
            <li className="nav-item">
              <a className="nav-link link-site" href="#">Sobre</a>
            </li>            
            <li className="nav-item">
              <a className="nav-link link-site" href="#">Contato</a>
            </li>            
          </ul>          
        </div>
      </nav>
      {empresa && (
        <>
          <div className="jumbotron page-header d-flex flex-column justify-content-around" 
            style={{
              backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0.7), 
                rgba(0, 0, 0, 0.7)
              ), url('${imgTopo}')`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',            
          }}>
            <div className="container d-flex flex-column justify-content-around">
              <h1 className="titulo-page-empresa">{empresa.nome}</h1>
              <p className={"lead " + (config.isMobile ? 'w-100' : 'w-50')}>
                {empresa.descricao}
              </p>                    
               
            </div>
            <div className="container d-flex flex-row">
              <a href="#" className="mr-3 page-link-menu">Home</a> | <a href="#" className="ml-3 mr-3 page-link-menu">Serviços</a> | <a href="#" className="page-link-menu ml-3">Contato</a>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <h1 className="titulo-sobre" style={{fontWeight: 'bold', color: 'rgba(0,0,128)',}}>SOBRE</h1>
              <h1 className="titulo-page-empresa">{empresa.nome}</h1>
              <p className="sobre-empresa">
                {empresa.descricao}
              </p>
              <div style={{marginTop: 50, textAlign: 'center', marginBottom: 50,}}>
                <img src={facebook} width="32" height="32"/>
                <img style={{marginLeft: 15}} src={twitter} width="32" height="32"/>
                <img style={{marginLeft: 15}} src={google} width="32" height="32"/>
              </div>  
            </div>
            <div className="col-md-6">
              <div className="row">
                {imagens.map((img, index) => 
                  img.area == '1' && (
                  <div key={index} className={index < 2 ? 'col-6 col-md-6' : 'col-12 col-md-12'}>
                      <img src={img.arquivo} className={index < 2 ? '' : 'pt-3'} height={index < 2 ? '142px' : '300px'} width="100%"/>
                  </div>
                  )
                )}
              </div>
            </div>

          </div>

        </>
      )}
      <br />
      <br />

      <div className="jumbotron page-header d-flex flex-column justify-content-around" 
        style={{
          backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
          ), url(${bg})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center',            
      }}>
        <div className="w-100 text-center titulo-page-empresa">
          SERVIÇOS
        </div>
        <div className="container-fluid">
          <Carousel 
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay
          keyBoardControl={true}
          transitionDuration={2000}
          itemclassName="p-2">
          {servicos.map((serv, index) => 
            <div key={index} className="text-center img-thumbnail" style={{height: 200,}}>
                <img style={{marginBottom: 10,}} height="100px" className="mx-auto d-block p-1 border" src={serv.imagem} alt={serv.nome}/>
                <strong style={{color: '#000'}}>{serv.nome}</strong>
                <p style={{color: '#000'}}>{serv.descricao}</p>
            </div>
          )}
          </Carousel>
        </div>
      </div>

      <div>
        <h1 className="w-100 text-center titulo-page-empresa">CONTATOS</h1>
        {!config.isMobile ? (
          <form>
            <div className="row">
              <div className="col">
                <textarea rows="10" className="form-control form-control-lg" placeholder="Mensagem" ></textarea>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="Assunto" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="Seu Nome" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="E-mail" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12">
                    <button className="btn btn-secondary btn-block btn-lg">Enviar</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <form>
                <div className="form-group row">
                  <div className="col-12">
                    <textarea rows="10" className="form-control form-control-lg" placeholder="Mensagem" ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="Assunto" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="Seu Nome" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <input type="text" className="form-control form-control-lg" placeholder="E-mail" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <button className="btn btn-secondary btn-block btn-lg">Enviar</button>
                  </div>
                </div>
          </form>
        )}
      </div>

      <br />

      <FooterSite />
    </div>
  );
}

export default EmpresaPage;
