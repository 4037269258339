import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import * as yup from 'yup';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';

function EmpresaCadastro({history, usuario, location}) {
    const schema = yup.object({
        nome: yup.string().min(1).required(),
        descricao: yup.string().min(1).required(),
    });
    const [itemServico, setItemServico] = useState(null);
    const [servico, setServico] = useState('');
    const [descricao_servico, setDescricaoServico] = useState('');
    const [itemDestaque, setItemDestaque] = useState(null);
    const [destaque, setDestaque] = useState('');
    const [descricao_destaque, setDescricaoDestaque] = useState('');
    const [registro, setRegistro] = useState({
        id_empresa: '',
        nome: '',
        descricao: '',
        telefone: '',
        endereco: '',
        instagram: '',
        facebook: '',
        twitter: '',
        id_edificiocomercial: usuario.id_edificiocomercial ? usuario.id_edificiocomercial : 1,
        url: '',
        site: '',
        email: '',
        id_usuario: usuario.id_usuario,
        status: '0',
        tags: '',
        titulo_banner: '',
        descricao_banner: '',
        whatsapp: '',
    });

    const [servicos, setServicos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [destaques, setDestaques] = useState([]);
    const [listacategoria, setListaCategorias] = useState([]);
    const [categoria, setCategoria] = useState('');

    useEffect(() => {
        async function load(){
            const resCategorias = await api.get('/categorias');
            setListaCategorias(resCategorias.data);
        }
        
        if (location.state)
        {
            const reg_ = location.state.registro;
            setRegistro(reg_);
            carregaServicos(reg_.id_empresa)
            carregaDestaques(reg_.id_empresa);
            carregaCategorias(reg_.id_empresa);
        }

        load();
    }, [])

    async function carregaServicos(id_empresa){
        var retServ = await api.get('/empresa/servico', { params: {
            id_empresa: id_empresa,
        }});

        const list = retServ.data.map(item => {
            item.delete = 'N';
            return item;
        });
        console.log(list);
        setServicos(list);
    }

    async function carregaCategorias(id_empresa){
        var retDest = await api.get('/empresa/categoria', { params: {
            id_empresa: id_empresa,
        }});

        const list = retDest.data.map(item => {
            item.delete = 'N';
            return item;
        });
        setCategorias(list);

    }

    async function carregaDestaques(id_empresa){
        var retDest = await api.get('/empresa/destaque', { params: {
            id_empresa: id_empresa,
        }});

        const list = retDest.data.map(item => {
            item.delete = 'N';
            return item;
        });
        console.log(list);
        setDestaques(list);

    }

    
    const gravar = async (e) => {
        e.preventDefault();
        
        const valido = await schema.isValid(registro);
        let id = '';
        if (!valido)
        {
            toast.warning('Informe todos os campos obrigatórios.');
            return false;
        }
        if (registro.id_empresa)
        {
            var update = await api.put('/empresa', registro);
            id = update.data.id_empresa;
        }
        else
        {
            var save = await api.post('/empresa', registro);
            id = save.data.id_empresa;
        }

        var retCategorias = await api.post('/empresa/categoria', {
            id_empresa: id,
            categorias
        });
        
        var retServicos = await api.post('/empresa/servico', {
            id_empresa: id,
            servicos
        });

        var retDestaques = await api.post('/empresa/destaque', {
            id_empresa: id,
            destaques
        });

        console.log(retDestaques);

        toast.success('Registro salvo com sucesso!');
        history.replace('/empresas');
    }

    function addServicos (e){
        e.preventDefault();
        setServicos([...servicos, {
            nome: servico,
            descricao: descricao_servico,
            delete: 'N',
        }]);

        setServico('');
        setDescricaoServico('');
    }

    function addDestaque (e){
        e.preventDefault();
        setDestaques([...destaques, {
            titulo: destaque,
            descricao: descricao_destaque,
            delete: 'N',
        }]);
        setDestaque('');
        setDescricaoDestaque('');
    }

    function addCategoria (e){
        e.preventDefault();

        const cat = listacategoria.find(item => item.id_categoria == categoria );
        console.log(cat);
        setCategorias([...categorias, {
            id_categoria: categoria,
            nome: cat.nome,
            delete: 'N',
        }]);

        setCategoria('');
    }

    function removeCat(index){
        let array = [...categorias];

        const result = array.map((item, i) => {
            if (i == index){
                item.delete = 'S';
            }
            return item
        })
        console.log(result);
        setCategorias(result);
    }

    function removeServ(index){
        let array = [...servicos];

        const result = array.map((item, i) => {
            if (i == index){
                item.delete = 'S';
            }
            return item
        })
        console.log(result);
        setServicos(result);
    }

    function editaServico(item, index){    
        item.index = index;
        setItemServico(item)
        console.log(item);
        window.$('#modalServico').modal('toggle');

    }

    function editaDestaque(item, index){    
        item.index = index;
        setItemDestaque(item);
        console.log(item);
        window.$('#modalDestaque').modal('toggle');
    }

    function salvarServicos(){
        let array = [...servicos];

        const result = array.map((item, index) => {
            if (index == itemServico.index){
                item.nome = itemServico.nome;
                item.descricao = itemServico.descricao;
            }
            return item;
        })
        console.log(result);
        setServicos(result);
        setItemServico(null);
    }

    function salvarDestaques(){
        let array = [...destaques];

        const result = array.map((item, index) => {
            if (index == itemDestaque.index){
                item.titulo = itemDestaque.titulo;
                item.descricao = itemDestaque.descricao;
            }
            return item;
        })
        console.log(result);
        setDestaques(result);
        setItemDestaque(null);
    }

    function removeDest(index){
        let array = [...destaques];

        const result = array.map((item, i) => {
            if (i == index){
                item.delete = 'S';
            }
            return item
        })
        console.log(result);
        setDestaques(result);
    }

    

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="empresas"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/empresas">Empresas</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Cadastro</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Empresa *</label>
                                            <input type="text" className="form-control" value={registro.nome} onChange={e => setRegistro({...registro, nome: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-4">
                                            <label>URL *</label>
                                            <input type="text" className="form-control" value={registro.url} onChange={e => setRegistro({...registro, url: e.target.value})}/>
                                        </div>  
                                    </div>  
                                    <div className="form-row">                                        
                                        <div className="form-group col-md-12">
                                            <label>Sobre a Empresa *</label>
                                            <textarea type="text" rows={8} className="form-control" value={registro.descricao} onChange={e => setRegistro({...registro, descricao: e.target.value})} />
                                        </div> 
                                    </div> 

                                    <div className="form-row">                                        
                                        <div className="form-group col-md-12">
                                            <label>Titulo Banner *</label>
                                            <input type="text" className="form-control" value={registro.titulo_banner} onChange={e => setRegistro({...registro, titulo_banner: e.target.value})}/>
                                        </div> 
                                    </div> 

                                    <div className="form-row">                                        
                                        <div className="form-group col-md-12">
                                            <label>Descrição Banner *</label>
                                            <textarea type="text" rows={4} className="form-control" value={registro.descricao_banner} onChange={e => setRegistro({...registro, descricao_banner: e.target.value})} />
                                        </div> 
                                    </div> 
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>Telefone *</label>
                                            <input type="text" className="form-control" value={registro.telefone} onChange={e => setRegistro({...registro, telefone: e.target.value})}/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Whatsapp</label>
                                            <input type="text" className="form-control" value={registro.whatsapp} onChange={e => setRegistro({...registro, whatsapp: e.target.value})}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Endereço *</label>
                                            <input type="text" className="form-control" value={registro.endereco} onChange={e => setRegistro({...registro, endereco: e.target.value})}/>
                                        </div>  
                                    </div>  

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>E-mail *</label>
                                            <input type="text" className="form-control" value={registro.email} onChange={e => setRegistro({...registro, email: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-6">
                                            <label>Site *</label>
                                            <input type="text" className="form-control" value={registro.site} onChange={e => setRegistro({...registro, site: e.target.value})}/>
                                        </div>  
                                    </div> 

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Instagram</label>
                                            <input type="text" className="form-control" value={registro.instagram} onChange={e => setRegistro({...registro, instagram: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-4">
                                            <label>Facebook</label>
                                            <input type="text" className="form-control" value={registro.facebook} onChange={e => setRegistro({...registro, facebook: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-4">
                                            <label>Twitter</label>
                                            <input type="text" className="form-control" value={registro.twitter} onChange={e => setRegistro({...registro, twitter: e.target.value})}/>
                                        </div>  
                                    </div> 

                                    <div className="form-row">                                         
                                        <div className="form-group col-md-2">
                                            <label>Status</label>
                                            <select className="form-control" value={registro.status} onChange={e => setRegistro({...registro, status: e.target.value})} >
                                                <option value="0">Ativo</option>
                                                <option value="1">Desativado</option>
                                            </select>
                                        </div>  
                                    </div>   

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label>Serviço</label>
                                                    <input type="text" className="form-control" value={servico} onChange={e => setServico(e.target.value)}/>
                                                </div>  
                                                <div className="form-group col-md-6">
                                                    <label>Descrição</label>
                                                    <input type="text" className="form-control" value={descricao_servico} onChange={e => setDescricaoServico(e.target.value)}/>
                                                </div>  
                                                <div className="form-group col-md-2 d-flex align-items-end">                                            
                                                    <button onClick={addServicos} className="btn btn-primary btn-block">Adicionar</button>
                                                </div>  
                                            </div> 

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <table className="table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Serviço</th>
                                                                <th>Descrição</th>
                                                                <th></th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                            {servicos.map((item, index) => (
                                                                <tr key={index} style={{color: (item.delete == 'S' ? 'red' : '')}}>
                                                                    <td>{item.nome}</td>
                                                                    <td>{item.descricao}</td>
                                                                    <td style={{width: 80,}}>
                                                                        <i onClick={(e) => editaServico(item, index)} className="far fa-edit button-icon mr-3"></i>
                                                                        <i onClick={(e) => removeServ(index)} className="far fa-trash-alt button-icon"></i>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label>Titulo Destaque</label>
                                                    <input type="text" className="form-control" value={destaque} onChange={e => setDestaque(e.target.value)}/>
                                                </div>  
                                                <div className="form-group col-md-6">
                                                    <label>Descrição</label>
                                                    <input type="text" className="form-control" value={descricao_destaque} onChange={e => setDescricaoDestaque(e.target.value)}/>
                                                </div>  
                                                <div className="form-group col-md-2 d-flex align-items-end">                                            
                                                    <button onClick={addDestaque} className="btn btn-primary btn-block">Adicionar</button>
                                                </div>  
                                            </div> 

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <table className="table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Destaque</th>
                                                                <th>Descrição</th>
                                                                <th></th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                            {destaques.map((item,index) => (
                                                            <tr key={index} style={{color: (item.delete == 'S' ? 'red' : '')}}>
                                                                <td>{item.titulo}</td>
                                                                <td>{item.descricao}</td>
                                                                <td style={{width: 80,}}>
                                                                    <i onClick={(e) => editaDestaque(item, index)} className="far fa-edit button-icon mr-3"></i>
                                                                    <i onClick={(e) => removeDest(index)}  className="far fa-trash-alt button-icon"></i>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="form-group col-md-10">
                                                    <label>Categoria</label>
                                                    <select className="form-control"  value={categoria} onChange={e => setCategoria(e.target.value)} value={categoria}>
                                                        <option value="">Todos</option>
                                                        {listacategoria.map((item, index) => (
                                                            <option key={index} value={item.id_categoria}>{item.nome}</option>
                                                        ))}
                                                    </select>
                                                </div>  
                                                <div className="form-group col-md-2 d-flex align-items-end">                                            
                                                    <button onClick={addCategoria} className="btn btn-primary btn-block">Adicionar</button>
                                                </div>  
                                            </div> 

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <table className="table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Categoria</th>
                                                                <th></th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                            {categorias.map((item,index) => (
                                                            <tr key={index} style={{color: (item.delete == 'S' ? 'red' : '')}}>
                                                                <td>
                                                                    {item.nome}
                                                                </td>
                                                                <td style={{width: 50,}}><i onClick={(e) => removeCat(index)} className="far fa-trash-alt button-icon"></i></td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label>Palavras chave</label>
                                            <input type="text" className="form-control" value={registro.tags} onChange={e => setRegistro({...registro, tags: e.target.value})}/>
                                        </div>  
                                    </div> 
                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <a className="btn btn-primary col-md-2" href="/empresas">Voltar</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade " data-backdrop="static" data-keyboard="false" id="modalServico" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {itemServico && (
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        SERVIÇO
                                    </h5>
                                    <button onClick={() => setItemServico(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                     <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Serviço</label>
                                            <input type="text" className="form-control" value={itemServico.nome} onChange={e => setItemServico({...itemServico, nome: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-7">
                                            <label>Descrição</label>
                                            <input type="text" className="form-control" value={itemServico.descricao} onChange={e => setItemServico({...itemServico, descricao: e.target.value})}/>
                                        </div>  
                                    </div> 
                                </div>
                                <div className="modal-footer">
                                    <button onClick={salvarServicos} className="btn btn-primary btn-block col-md-3" data-dismiss="modal">Salvar</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="modal fade " data-backdrop="static" data-keyboard="false" id="modalDestaque" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {itemDestaque && (
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        DESTAQUE
                                    </h5>
                                    <button onClick={() => setItemDestaque(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Titulo Destaque</label>
                                            <input type="text" className="form-control" value={itemDestaque.titulo} onChange={e => setItemDestaque({...itemDestaque, titulo: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-7">
                                            <label>Descrição</label>
                                            <input type="text" className="form-control" value={itemDestaque.descricao} onChange={e => setItemDestaque({...itemDestaque, descricao: e.target.value})}/>
                                        </div>  
                                    </div> 
                                </div>
                                <div className="modal-footer">
                                    <button onClick={salvarDestaques} className="btn btn-primary btn-block col-md-3" data-dismiss="modal">Salvar</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(EmpresaCadastro);