import React from 'react';

import config from '../../services/config';

function Footer() {
  return (
    <footer className="main-footer" style={{
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      bottom: 0,
      background: '#CCC',
      width: '100%',
      color: '#000'
    }}>
        <strong style={{
          alignSelf: 'flex-start'
        }}>&copy; 2021 Conexi </strong> <small className="pl-2"> - Todos os direitos reservados.</small>
        
    </footer>
  );
}

export default Footer;